<template>
  <v-card data-vuetify>
    <div v-if="!isLoading">
      <v-card-title class="justify-center">
        <span class="text-h6 font-weight-bold navy--text"
          >Cadastro de Usuário</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters class="justify-start mt-2 mb-1">
              <v-col cols="12" align="start">
                <v-autocomplete
                  ref="nomeCompleto"
                  :error-messages="errorMessages"
                  v-model="user.pcemprId"
                  item-text="NOME"
                  item-value="MATRICULA"
                  :items="getEmployes"
                  placeholder="Funcionário"
                  outlined
                  dense
                  :rules="[rules.name]"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row justify="space-around" class="justify-start my-0 mb-1">
              <v-col cols="6">
                <default-text-field
                  label="Login"
                  v-model="user.login"
                  id="order"
                  name="order"
                  type="text"
                  :rules="[rules.login]"
                  :hide-details="false"
                />
              </v-col>

              <v-col cols="6">
                <default-text-field
                  label="Senha"
                  v-model="user.password"
                  id="order"
                  name="order"
                  type="text"
                  :hide-details="false"
                  :rules="[rules.senha]"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" align="start">
                <div
                  class="justify-center navy--text"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Impressoras
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="justify-start mt-2 mb-1">
              <v-col cols="12">
                <v-autocomplete
                  ref="impressora"
                  :error-messages="errorMessages"
                  v-model="user.impressoraId"
                  item-text="nome"
                  item-value="impressoraId"
                  :items="getImpressoras"
                  placeholder="Impressoras"
                  outlined
                  dense
                  :rules="[rules.impressora]"
                  id="order"
                  name="order"
                  type="text"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="start">
                <div
                  class="justify-center navy--text"
                  style="text-transform: none !important; font-weight: bolder"
                >
                  Funções
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="justify-start mt-2 mb-1">
              <v-col cols="12">
                <v-autocomplete
                  ref="funcoes"
                  :error-messages="errorMessages"
                  v-model="user.roles"
                  item-text="NAME"
                  item-value="ID"
                  :items="getRoles"
                  placeholder="Funções"
                  outlined
                  dense
                  :rules="[rules.funcao]"
                  id="order"
                  name="order"
                  type="text"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters class="justify-start mt-2 mb-1">
              <v-col cols="12">
                <v-autocomplete
                  ref="permissoes"
                  :error-messages="errorMessages"
                  v-model="user.permission"
                  item-text="descricao"
                  item-value="id"
                  :items="getPermissions"
                  placeholder="Permissões"
                  outlined
                  dense
                  :rules="[rules.permissao]"
                  id="order"
                  name="order"
                  type="text"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="4">
                <default-button
                  @click="sendCreateUser"
                  :loading="isLoadingButton"
                  :disabled="!isFormValid()"
                >
                  <span>Salvar</span>
                </default-button>
              </v-col>
              <v-col cols="4">
                <default-button @click="closeModal">
                  <span>Cancelar</span>
                </default-button>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
    <div v-else>
      <v-col align="center">
        <default-spinner></default-spinner>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import routesValidationMixin from "../../../mixins/routesValidationMixin";
import httpRequestMixin from "../../../mixins/httpRequestMixin";
import DefaultButton from "../../../shared/components/vuetify/DefaultButton.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { DefaultButton, DefaultTextField, DefaultSpinner },
  mixins: [routesValidationMixin, httpRequestMixin],
  data() {
    return {
      showModal: false,
      isLoading: false,
      valid: true,
      isLoadingButton: false,
      errorMessages: "",
      user: {
        login: "",
        password: "",
        impressoraId: null,
        pcemprId: null,
        roles: null,
        permission: null,
      },
      rules: {
        name: (value) => !!value || "O campo nome não pode ser vazio.",
        login: (value) => !!value || "O campo login não pode ser vazio.",
        senha: (value) => !!value || "O campo senha não pode ser vazio.",
        impressora: (value) =>
          !!value || "O campo impressora não pode ser vazio.",
        funcao: (value) => !!value || "O campo função não pode ser vazio.",
        permissao: (value) =>
          !!value || "O campo permissão não pode ser vazio.",
      },
    };
  },
  mounted() {
    this.getNovoUsuarioData();
  },
  methods: {
    ...mapActions("usuarios", [
      "listEmployes",
      "listRoles",
      "listPermissions",
      "listImpressoras",
      "createUser",
    ]),
    async sendCreateUser() {
      this.isLoadingButton = true;
      const body = {
        login: this.user.login,
        password: this.user.password,
        impressoraId: this.user.impressoraId,
        pcemprId: this.user.pcemprId,
        roles: [this.user.roles],
        permission: [this.user.permission],
      };
      let response = await this.createUser(body);

      if (response.status >= 200 && response.status < 300) {
        this.$emit("listUsuarios");
        this.$emit("closeCreateUserDialog");
        this.isLoadingButton = false;
      }

      this.isLoadingButton = false;
    },
    closeModal() {
      this.$emit("closeCreateUserDialog");
    },
    resetForm() {
      this.user = {
        login: "",
        password: "",
        impressoraId: null,
        pcemprId: null,
        roles: null,
      };
      this.$refs.form.resetValidation();
    },

    async getNovoUsuarioData() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.listEmployes(),
          this.listRoles(),
          this.listPermissions(),
          this.listImpressoras(),
        ]);
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },

    isFormValid() {
      return (
        this.user.login &&
        this.user.password &&
        this.user.impressoraId &&
        this.user.pcemprId &&
        this.user.roles &&
        this.user.permission
      );
    },
  },
  computed: {
    ...mapGetters("usuarios", [
      "getEmployes",
      "getRoles",
      "getPermissions",
      "getImpressoras",
    ]),
  },
};
</script>
