<template>
  <div>
    <default-table-body-row>
      <v-col sm="6" md="4" lg="4" xl="4">
        <span class="mb-3 d-xl-none d-lg-none">
          <b>Nome: </b>
        </span>
        {{ user.nome }}
      </v-col>
      <v-col sm="6" md="2" lg="2" xl="2">
        <span class="mb-3 d-xl-none d-lg-none">
          <b> Login: </b>
        </span>
        <span class="font-weight-bold secondary--text">
          {{ user.email }}
        </span>
      </v-col>
      <v-col sm="4" md="2" lg="2" xl="2">
        <span class="mb-3 d-xl-none d-lg-none">
          <b> Função: </b>
        </span>
        <div class="func-tag-cont">
          <div class="func-tag">
            {{ user.descricao }}
          </div>
        </div>
      </v-col>
      <v-col sm="4" md="2" lg="2" xl="2">
        <span class="mb-3 d-xl-none d-lg-none">
          <b> Impressora: </b>
        </span>
        <div class="">
          <span class="font-italic">
            {{ user.impressora }}
          </span>
        </div>
      </v-col>
      <v-col sm="4" md="2" lg="2" xl="2">
        <default-fab
          tooltip-text="Editar"
          :loading="loadingButton"
          @click="openEditUserDialog()"
        >
          <v-icon>mdi-pencil</v-icon>
        </default-fab>
      </v-col>
    </default-table-body-row>
    <v-dialog
      v-model="dialogEditUser"
      scrollable
      max-width="720"
      transition="dialog-bottom-transition"
    >
      <edit-dialog-user
        :key="reRender"
        @closeEditUserDialog="closeEditUserDialog"
        @listUsuarios="listUsuarios"
        :userEdit="user"
      />
    </v-dialog>
  </div>
</template>
<script>
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";
import EditDialogUser from "./EditDialogUser.vue";
export default {
  name: "UserListCard",
  components: {
    DefaultTableBodyRow,
    DefaultFab,
    EditDialogUser,
  },
  data: () => ({
    loadingButton: false,
    dialogEditUser: false,
    reRender: 0,
  }),
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openEditUserDialog() {
      this.dialogEditUser = true;
      this.reRender += 1;
    },

    closeEditUserDialog() {
      this.dialogEditUser = false;
    },

    listUsuarios() {
      this.$emit("listUsuarios");
    },
  },
};
</script>
<style scoped>
.default-button-style {
  text-align: center;
  outline: none;
  border-radius: 1rem;
  padding: 1rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  transition: background-color, padding 0.15s;
  background-color: #cc5d02 !important;
  color: white !important;
  border: none;
}

.default-button-style:hover {
  background-color: #e6781e !important;
  color: white !important;
}

.listing-section {
  list-style-type: none;
  border-right: 0.063rem solid rgba(83, 83, 83, 0.14);
  padding: 1.25rem;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
  align-self: center;
}
.listing-section-last {
  list-style-type: none;
  padding: 1.25rem;
  text-align: center;
}
.listing-card {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: #eef0f8;
  color: #2e2e2e;
  border-radius: 1rem;
}
.listing-card:hover {
  background-color: rgba(215, 219, 223, 0.84);
  border-radius: 1rem;
  transition: 300ms all ease-in-out;
}
</style>
