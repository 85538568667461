<template>
  <v-container fluid>
    <div>
      <user-list-header @filter="filter" @listUsuarios="getListUsuarios" />
      <default-table-head class="d-none d-md-flex">
        <v-col lg="4" xl="4" class="border-right"> Nome </v-col>
        <v-col lg="2" xl="2" class="border-right"> Login </v-col>
        <v-col lg="2" xl="2" class="border-right"> Função </v-col>
        <v-col lg="2" xl="2" class="border-right"> Impressora </v-col>
        <v-col lg="2" xl="2"> </v-col>
      </default-table-head>
      <div v-if="getIsLoading">
        <default-spinner class="my-2" />
      </div>
      <div v-else>
        <template>
          <user-list-card
            v-for="(user, index) in getListingUsuarios"
            :key="index"
            :user="user"
            @edit="$router.push('/users/' + user.usuarioId)"
            @delete="deleteUser(user.usuarioId)"
          />
        </template>
        <default-pagination
          v-model="getUsuariosPagination.currentPage"
          :length="getUsuariosPagination.lastPage"
          @input="paginate(getUsuariosPagination.currentPage)"
          :disabled="getListingUsuarios.length === 0"
        />
      </div>
      <modal-alert-baia :modalAlert="modalAlert" />
    </div>
  </v-container>
</template>

<script>
import routesValidationMixin from "../../../mixins/routesValidationMixin";
import httpRequestMixin from "../../../mixins/httpRequestMixin";
import UserListHeader from ".././components/UserListHeader.vue";
import UserListCard from ".././components/UserListCard.vue";
import ModalAlertBaia from "../../configuracoes_baias/components/ModalAlertBaia.vue";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultTableHead from "@/shared/components/vuetify/DefaultTableHead.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalAlertBaia,
    UserListHeader,
    UserListCard,
    DefaultPagination,
    DefaultTableHead,
    DefaultSpinner,
  },
  data() {
    return {
      isLoading: false,
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
        lastPage: 0,
        previousPage: 0,
        nextPage: 0,
      },
      modalAlert: {
        message: "",
        variant: "",
      },
    };
  },
  watch: {
    async getListChanged() {
      await this.usersRepository.getUsers();
    },
  },
  mounted() {
    this.getListUsuarios();
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  methods: {
    ...mapActions("usuarios", ["usuarios", "setIsLoading", "setUrlParams"]),

    async getListUsuarios() {
      await this.usuarios();
    },

    async paginate(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.getListUsuarios();
      this.setIsLoading(false);
    },

    async filter(filter) {
      console.log(filter);
      this.setUrlParams(filter);
      await this.getListUsuarios();
    },
  },
  computed: {
    ...mapGetters("usuarios", [
      "getListingUsuarios",
      "getUsuariosPagination",
      "getIsLoading",
      "getUrlParams",
    ]),
  },
};
</script>
<style scoped>
.list-titles {
  list-style-type: none;
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
  padding: 1.25rem;
}
li {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
}
.list-component {
  background-color: #eef0f8;
  color: #ffffff;
  border-radius: 1rem;
}
.listing-component-navy-color {
  background: #120947;
}
</style>
