<template>
  <div class="mb-4 mt-3">
    <v-row class="mt-2" justify="center" align="center">
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <default-text-field label="Nome" v-model="nomeUsuario" type="text" />
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <default-text-field
          label="Login"
          v-model="loginUsuario"
          id="order"
          name="order"
          type="text"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        class="pb-3"
        align-self="center"
      >
        <default-filter-button
          :loading="isButtonLoading"
          @filter="filter"
          @cleanFilter="cleanFilter"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        class="pb-3"
        align-self="center"
      >
        <default-button @click="openCreateUserDialog">Cadastro</default-button>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogCreateUser"
      scrollable
      max-width="720"
      transition="dialog-bottom-transition"
    >
      <created-dialog-user
        :key="reRender"
        @closeCreateUserDialog="closeCreateUserDialog"
        @listUsuarios="listUsuarios"
      />
    </v-dialog>
  </div>
</template>
<script>
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultFilterButton from "@/shared/components/vuetify/DefaultFilterButton.vue";
import CreatedDialogUser from "./CreatedDialogUser.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DefaultButton,
    DefaultTextField,
    DefaultFilterButton,
    CreatedDialogUser,
  },
  name: "UserListHeader",
  data() {
    return {
      nomeUsuario: "",
      loginUsuario: "",
      dialogCreateUser: false,
      reRender: 0,
      isButtonLoading: false,
    };
  },
  props: {
    filtrarColor: {
      type: String,
      default: "orange-color",
    },
  },
  methods: {
    ...mapActions("usuarios", ["usuarios", "setUrlParams"]),
    filter() {
      const body = {
        nome: this.nomeUsuario,
        email: this.loginUsuario,
      };
      this.setUrlParams(body);
      this.$emit("filter", body);
    },

    cleanFilter() {
      this.nomeUsuario = "";
      this.loginUsuario = "";
    },

    openCreateUserDialog() {
      this.dialogCreateUser = true;
      this.reRender += 1;
    },

    closeCreateUserDialog() {
      this.dialogCreateUser = false;
    },

    listUsuarios() {
      this.$emit("listUsuarios");
    },
  },
  computed: {
    ...mapGetters("usuarios", ["getUrlParams"]),
  },
};
</script>
<style scoped>
.datepicker-style {
  margin: 8px 0 23px;
  padding: 7px;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: #353535;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 11px;
  outline: 0;
  box-sizing: border-box;
}
.listing-title {
  font-family: inherit;
  font-weight: bold;
  color: #2e2e2e;
}
.filter-input:hover {
  border: none;
  outline: 0.063rem solid #212529;
}
</style>
